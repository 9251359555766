<div class="text-start my-3">
  <h5 class="text-secondary fw-bold">ตรวจสอบสิทธิบุคคลในครอบครัว</h5>
</div>
<div class="row d-flex mb-3">
  <div class="col-12 text-end">
    <button class="btn btn-green fw-bold" (click)="openAddFamilyModal()">
      <i class="bi bi-plus"></i> เพิ่มคนในครอบครัว
    </button>
  </div>
</div>
<div class="card mt-3">
  <div class="card-body">
    <div class="row my-3 ms-2">
      <h6 class="fw-bold text-secondary">เลือกบุคคลในครอบครัว</h6>
    </div>
    <div class="container-fluid p-4">
      <div class="table">
        <table class="table table-borderless table-hover col-12">
          @for (member of allFamilyMembers(); let idx = $index; track member) {
            <tr class="px-5">
              <td>
                <div class="row d-flex py-3">
                  <div class="col-xl-2 mb-3">
                    <div class="d-flex justify-content-end ">
                      @if (member?.relation?.id != 1 && member?.relation?.id != 2) {
                        <a type="button"
                           class="btn btn-warning text-center">
                          <i class="bi bi-pencil-fill text-white"></i>
                        </a>
                        <a type="button"
                           class="btn btn-red text-center ms-1">
                          <i class="bi bi-trash text-white"></i>
                        </a>
                      }
                    </div>
                  </div>
                  <div class="col-xl-4 d-flex mb-3">
                    <div class="col-5 text-xl-center">ชื่อ-นามสกุล:</div>
                    <div class="col-7">{{ member?.memberData?.firstName }} {{ member?.memberData?.lastName }}</div>
                  </div>
                  <div class="col-xl-4 row d-flex mb-3">
                    <div class="col-5 text-xl-center">ความสัมพันธ์:</div>
                    <div class="col-7">{{ member?.relation?.name }}</div>
                  </div>
                  <div class="col-xl-2 text-center">
                    <button class="btn btn-primary px-5" (click)="select(member)">เลือก</button>
                  </div>
                </div>
              </td>
            </tr>
          }
        </table>
      </div>
    </div>
  </div>
</div>
