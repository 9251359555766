<div id="modal-content" class="modal-content">
  <div class="modal-body">
    <div class="container">
      <div class="row d-flex mt-3">
        <div class="col-10">
          <h5>
            <span class="fw-bold">ค้นหาและเพิ่มคนในครอบครัว</span>
          </h5>
        </div>
        <div class="col-2 text-end">
          <button class="btn-close" type="button" aria-label="Close" (click)="close()"></button>
        </div>
      </div>
      <form>
        <div class="container mt-3">
          <div class="row mt-5">
            <div class="col-xl-6 d-xl-flex">
              <label for="pid" class="col-form-label col-xl-5 ui-require">เลขประจำตัวประชาชน</label>
              <div class="col-xl-7">
                <input type="text" id="pid" name="memberData[personalId]" class="form-control">
              </div>
            </div>
            <div class="col-xl-6 d-xl-flex">
              <label for="birthDate" class="col-form-label col-xl-5">วันเดือนปีเกิด</label>
              <div class="col-xl-7">
                <input id="birthDate" name="birthDate" class="form-control">
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-xl-6 d-xl-flex mb-3">
              <label for="relation" class="col-form-label ui-require col-xl-5">ความสัมพันธ์</label>
              <div class="col-xl-7">
                <select id="relation" name="relation[id]" class="form-select">
                  <option value="">กรุณาระบุ</option>
                  @for (item of relations(); let idx = $index; track item) {
                    <option [value]="item.id">{{item.name}}</option>
                  }
                </select>
              </div>
            </div>
            <div class="col-xl-6 text-xl-start text-center">
              <button class="btn btn-primary" type="button">
                <span class="p-5">ค้นหา</span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="container">
      <hr>
      <div class="mt-5">
        <h6 class="fw-bold">ผลการค้นหา</h6>
      </div>
      <div class="row mt-3">
        <div class="col-xl-6 d-xl-flex">
          <label for="name" class="col-form-label col-xl-5">ชื่อ-นามสกุล</label>
          <div class="col-xl-7">
            <input type="text" id="name" class="form-control-plaintext">
          </div>
        </div>
        <div class="col-xl-6 d-xl-flex">
          <label for="birthDate" class="col-form-label col-xl-5">วันเดือนปีเกิด</label>
          <div class="col-xl-7">
            <input id="birthDateResult" class="form-control-plaintext">
          </div>
        </div>
      </div>
      <div class="card mt-5">
        <div class="container">
          <div class="mt-3">
                    <span class="ui-require">
                        เอกสารตามคำสั่งศาล
                    </span>
          </div>
          <div class="my-3">
            <input type="file" class="form-control">
          </div>
        </div>
      </div>
      <div class="row d-flex my-4">
        <div class="col-12 text-center">
          <button class="btn btn-green"><span class="p-5">บันทึก</span></button>
        </div>
      </div>
    </div>
  </div>
</div>
