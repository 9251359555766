import {
  AfterViewInit,
  Component,
  effect,
  ElementRef,
  inject,
  OnInit,
  signal,
  ViewChild,
  WritableSignal
} from '@angular/core';
import {Router} from '@angular/router';
import {FormsModule, NgForm} from '@angular/forms';
import {environment} from '../../../environments/environment';
import {NgbDate, NgbDateParserFormatter, NgbDatepickerModule, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {RightCheckResource} from '../../service/right-check-resource.service';
import {PublicRightCheckRequest, RightsDto} from '../../../generated-model/model';
import {CustomNgbDateParser} from '../../shere/gt-ng-datepicker/custom-ngb-date-parser';
import {CustomErrorComponent} from '../../shere/commons/custom-error.component';
import {SharedCommonModule} from '../../shere/commons/shared-common.module';
import {NgIf} from '@angular/common';
import {DigitOnlyModule} from '@uiowa/digit-only';
import {AlertDialogService} from '../../shere/commons/dialog/alert-dialog/alert-dialog.service';
import {FormatDateInputService} from '../../shere/commons/service/format-date-input.service';

declare var turnstile: any;

@Component({
  selector: 'app-public-right',
  imports: [
    FormsModule, NgbDatepickerModule, SharedCommonModule, CustomErrorComponent, NgIf, DigitOnlyModule
  ],
  templateUrl: './public-right.component.html',
  standalone: true,
  styleUrl: './public-right.component.scss',
  providers: [{provide: NgbDateParserFormatter, useClass: CustomNgbDateParser}]
})
export class PublicRightComponent implements OnInit, AfterViewInit {
  router: Router = inject(Router);
  rightSearchResource = inject(RightCheckResource);
  formatDateInput = inject(FormatDateInputService)
  environment = environment
  turnstileToken: WritableSignal<string> = signal(undefined);
  data = signal(undefined as RightsDto);
  params = signal({} as PublicRightCheckRequest)
  now = new Date()
  maxDate: NgbDateStruct

  alertDialogService = inject(AlertDialogService)

  @ViewChild('birthDateElement') birthDate: ElementRef<HTMLInputElement>
  @ViewChild('form') public ngForm: NgForm;

  constructor() {
    effect(() => {
      this.turnstileToken();
    });
  }

  ngOnInit() {
    this.maxDate = {year: this.now.getFullYear(), month: this.now.getMonth() + 1, day: this.now.getDate()}
    // window.onmessage = (e) => {
    //   if (e.data.turnstileToken) {
    //     this.turnstileToken.set(e.data.turnstileToken)
    //     console.log(e.data)
    //   }
    // };
  }

  verifyRight() {
    console.log(this.turnstileToken)
    this.ngForm.control.markAllAsTouched()
    this.params().turnstileResponse = this.turnstileToken()
    if (this.birthDate.nativeElement.value) {
      const params = {...this.params()}
      params.birthDate = this.formatDateInput.formatBirthToYYYYMMDD(this.birthDate.nativeElement.value)
      this.rightSearchResource.searchPublicRight(params).subscribe({
        next: value => {
          if (value.personalId) {
            this.router.navigate(['public-right-detail'], {state: value})
          } else {
            this.alertDialogService.open({content: "เลขประจำตัวประชาชน หรือ วันเดือนปีเกิดไม่ถูกต้อง", type: "danger"})
          }
        },
        error: err => {
          if (err?.status == 400) {
            this.data.set(undefined);
            console.log(err)
          }
        }
      })
    }
  }

  verifyRightByThaiD() {
    this.router.navigate(['secure'])
  }

  dateSelect($event: NgbDate) {
    console.log($event)
  }

  challengeCallback = (token) => {
    console.log(`Challenge Success ${token}`);
    this.turnstileToken.set(token);
  }

  ngAfterViewInit(): void {
    turnstile.ready(() => {
      turnstile.render("#cf-turnstile", {
        sitekey: environment.turnstileStiteKey,
        callback: this.challengeCallback,
      });
    });
  }
}
