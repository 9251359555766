import {Component, Input} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {SharedCommonModule} from '../../../../shere/commons/shared-common.module';
import {ThaiDateTimePipe} from '../../../../shere/commons/thai-datetime.pipe';
import {FundDto} from '../../../../../generated-model/model';

@Component({
  selector: 'app-dis-detail',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    SharedCommonModule,
    FormsModule,
    ThaiDateTimePipe
  ],
  templateUrl: './dis-detail.component.html',
  styleUrl: './dis-detail.component.scss'
})
export class DisDetailComponent {
  @Input() fund!: FundDto;

}
