import {Routes} from '@angular/router';
import {LayoutComponent} from './secure/layout/layout.component';
import {SelfRightCheckComponent} from './secure/right/self-right-check/self-right-check.component';
import {PublicRightComponent} from './public/public-right/public-right.component';
import {PublicRightDetailComponent} from './public/public-right-detail/public-right-detail.component';

export const routes: Routes = [
  {
    path: '',
    component: PublicRightComponent
  },
  {
    path: 'public-right-detail',
    component: PublicRightDetailComponent
  },
  {
    path: 'secure',
    component: LayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'self-right-check'
      },
      {
        path: 'self-right-check',
        component: SelfRightCheckComponent
      },
      {
        path: 'select-family-member',
        loadComponent: () => import('./secure/right/select-family/select-family.component').then(c => c.SelectFamilyComponent)
      },
      {
        path: 'select-family-member-detail',
        loadComponent: () => import('./secure/right/family-right-check/family-right-check.component').then(c => c.FamilyRightCheckComponent)
      },
      {
        path: 'change-hospital',
        loadComponent:()=>import('./secure/register-form/change-hospital/change-hospital.component').then(c=>c.ChangeHospitalComponent)
      },
      {
        path: 'history',
        loadComponent:()=>import('./secure/history/history.component').then(c=>c.HistoryComponent)
      },
      {
        path: 'register',
        loadComponent:()=>import('./secure/register/register.component').then(c=>c.RegisterComponent)
      },
      {
        path: 'register-form',
        loadComponent:()=>import('./secure/register-form/register-form.component').then(c=>c.RegisterFormComponent)
      }
    ]
  }
];
