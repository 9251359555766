import { Component } from '@angular/core';

@Component({
  selector: 'app-history',
  imports: [],
  templateUrl: './history.component.html',
  standalone: true,
  styleUrl: './history.component.scss'
})
export class HistoryComponent {

}
