import {Component, inject, Input, signal} from '@angular/core';
import {RelationshipRightsMember, Sex} from '../../../../../generated-model/model';
import {FormsModule} from '@angular/forms';
import {Router} from '@angular/router';

@Component({
  selector: 'app-family-right-check-detail',
  imports: [
    FormsModule
  ],
  templateUrl: './family-right-check-detail.component.html',
  standalone: true,
  styleUrl: './family-right-check-detail.component.scss'
})
export class FamilyRightCheckDetailComponent {
  @Input() member: RelationshipRightsMember

  protected readonly Sex = Sex;
  router = inject(Router)

  selectFamilyMember() {
    this.router.navigate(['secure', 'select-family-member'])
  }
}
