<div class="text-start my-3">
  <h5 class="text-secondary fw-bold">ประวัติการลงทะเบียน UC</h5>
</div>
<div class="card shadow-card mb-4">
  <div class="card-header">
    <div><i class="bi bi-search"></i> ค้นหา</div>
  </div>
  <div class="card-body">
    <form>
      <div class="container my-3">
        <div class="row mt-3">
          <div class="col-xl-6 col-sm-12 d-xl-flex">
            <label for="startDate"
                   class="col-form-label col-xl-3 col-sm-12">วันที่เริ่มต้น</label>
            <div class="col-xl-8 col-sm-12">
              <input id="startDate" class="form-control">
            </div>
          </div>
          <div class="col-xl-6 col-sm-12 d-xl-flex">
            <label for="endDate" class="col-form-label col-xl-3 col-sm-12">สิ้นสุด</label>
            <div class="col-xl-8 col-sm-12">
              <input id="endDate" class="form-control">
            </div>
          </div>
        </div>
        <div class="row mt-xl-3 mt-sm-0">
          <div class="col-xl-6 col-sm-12 d-xl-flex">
            <label for="pid"
                   class="col-form-label col-xl-3 col-sm-12">เลขประจำตัวประชาชน</label>
            <div class="col-xl-8 col-sm-12">
              <input type="text" id="pid" class="form-control">
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="text-center">
            <button class="btn btn-primary fw-bold me-2" type="submit">
              <i class="bi bi-search fw-bold me-1"></i>
              ค้นหา
            </button>
            <button class="btn btn-secondary fw-bold" type="reset">
              <i class="bi bi-eraser-fill me-1"></i>
              เคลียร์ข้อมูล
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="card  shadow-card mb-4">
  <div class="card-header">
    <div>
      ผลการค้นหาตามเงื่อนไขรายการที่ 0 จากทั้งหมด รายการ
    </div>
  </div>
  <div class="card-body">
    <div class="table-responsive">
      <table class="table table-striped table-bordered caption-top text-center">
        <thead>
        <tr>
          <th rowspan="3">ลำดับ</th>
          <th rowspan="3" nowrap>เลขที่คำร้อง</th>
          <th rowspan="3" nowrap>วันที่ลงทะเบียน</th>
          <th rowspan="3" nowrap>วันที่เริ่มใช้สิทธิ</th>
          <th rowspan="3" nowrap>เจ้าหน้าที่ผู้รับลงทะเบียน</th>
          <th rowspan="3" nowrap>ประเภทการยื่นคำร้อง</th>
          <th rowspan="3" nowrap>เลขที่ประจำตัวประชาชน<br>(ผู้ขอลงทะเบียน)</th>
          <th rowspan="3" nowrap>ชื่อ-นามสกุล<br>(ผู้ขอลงทะเบียน)</th>
          <th colspan="9">เครือข่ายหน่วยบริการที่ลงทะเบียนใหม่</th>
          <th rowspan="3" nowrap>รหัสสิทธิหลัก</th>
          <th rowspan="3" nowrap>สิทธิย่อย</th>
          <th rowspan="3" nowrap>ประเภทการลงนาม</th>
          <th rowspan="3" nowrap>เลขที่ประจำตัวประชาชน<br>(ผู้รับมอบอำนาจ)</th>
          <th rowspan="3" nowrap>ชื่อ-นามสกุล<br>(ผู้รับมอบอำนาจ)</th>
        </tr>
        <tr>
          <th colspan="2" nowrap>จังหวัดที่ลงทะเบียน</th>
          <th colspan="2" nowrap>หน่วยบริการปฐมภูมิ</th>
          <th colspan="2" nowrap>หน่วยบริการประจำ</th>
          <th colspan="2" nowrap>หน่วยบริการที่รับการส่งต่อ</th>
          <th rowspan="2" nowrap>paid model</th>
        </tr>
        <tr>
          <th>รหัส</th>
          <th>ชื่อจังหวัด</th>
          <th>รหัส</th>
          <th nowrap>ชื่อหน่วยบริการ</th>
          <th>รหัส</th>
          <th nowrap>ชื่อหน่วยบริการ</th>
          <th>รหัส</th>
          <th nowrap>ชื่อหน่วยบริการ</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td colspan="23">ไม่พบข้อมูล</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="card-footer">
    <div class="row d-flex float-end">
      <div class="col-auto">
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-start">
            <li class="page-item"><a class="page-link" href="#">&laquo</a></li>
            <li class="page-item"><a class="page-link" href="#">1</a></li>
            <li class="page-item"><a class="page-link" href="#">2</a></li>
            <li class="page-item"><a class="page-link" href="#">3</a></li>
            <li class="page-item"><a class="page-link" href="#">&raquo;</a></li>
          </ul>
        </nav>
      </div>
      <div class="col-auto">
        <select class="form-select">
          <option>10</option>
          <option>20</option>
          <option>30</option>
          <option>50</option>
        </select>
      </div>
    </div>
  </div>
</div>
