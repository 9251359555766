<div class="card shadow-card mb-4">
  <div class="card-body">
    <div class="mt-3"><span class="fw-bold text-primary">ข้อมูลประชากร ของ</span><span class="fw-bold text-warning"> สปสช.{{title}}</span>
    </div>
    <div class="row mt-xl-3 mt-sm-0">
      <div class="col-xl-6 d-xl-flex">
        <label for="pid" class="col-form-label col-xl-4">เลขประจำตัวประชาชน:</label>
        <div class="col-xl-8">
          <input type="text" id="pid" name="pid" [ngModel]="rightData?.personalId"
                 class="form-control-plaintext" readonly>
        </div>
      </div>
      <div class="col-xl-6 d-xl-flex">
        <label for="ิbirthdate" class="col-form-label col-xl-4">เดือนปีเกิด:</label>
        <div class="col-xl-8">
          <input type="text" id="ิbirthdate" name="ิbirthdate" [ngModel]="rightData?.birthDate | birthDateWithoutDate"
                 class="form-control-plaintext" readonly>
        </div>
      </div>
    </div>
    <div class="row mt-xl-3 mt-sm-0">
      <div class="col-xl-6 d-xl-flex">
        <label for="fullName" class="col-form-label col-xl-4">ชื่อ-สกุล:</label>
        <div class="col-xl-8">
          <input type="text" id="fullName" name="fullName" value="{{rightData?.firstName}} {{rightData?.lastName}}"
                 class="form-control-plaintext" readonly>
        </div>
      </div>
      <div class="col-xl-6 d-xl-flex">
        <label for="sex" class="col-form-label col-xl-4">เพศ:</label>
        <div class="col-xl-8">
          <input type="text" id="sex" name="sex" [ngModel]="rightData.sexDesc"
                 class="form-control-plaintext" readonly>
        </div>
      </div>
    </div>
    <div class="row mt-xl-3 mt-sm-0">
      @if (rightData?.statusDola == '1'){
        <div class="col-xl-6 d-xl-flex">
          <label for="statusDola" class="col-form-label col-xl-4">สภาพบุคคล:</label>
          <div class="col-xl-8">
            <input type="text" id="statusDola" name="statusDola" [ngModel]="rightData?.statusDolaDesc"
                   class="form-control-plaintext" readonly>
          </div>
        </div>
      } @else {
        <div class="col-xl-6 d-xl-flex">
          <label for="statusDola2" class="col-form-label col-xl-4">สภาพบุคคล:</label>
          <div class="col-xl-8">
            <input type="text" id="statusDola2" name="statusDola" [ngModel]="rightData?.statusDolaDesc"
                   class="form-control-plaintext" readonly>
          </div>
        </div>
      }
      <div class="col-xl-6 d-xl-flex">
        <label for="checkDate" class="col-form-label col-xl-4">ข้อมูล ณ วันที่:</label>
        <div class="col-xl-8">
          <input type="text" id="checkDate" name="checkDate" [ngModel]="rightData?.checkDate | thaidatetime"
                 class="form-control-plaintext" readonly>
        </div>
      </div>
    </div>
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" (activeIdChange)="onTabChange.emit($event)" [destroyOnHide]="false"
        class="nav-pills mt-4">
      <li [ngbNavItem]="1" [destroyOnHide]="true">
        <button class="btn btn-outline-blue" ngbNavLink>ข้อมูลตรวจสอบสิทธิ ณ ปัจจุบัน</button>
        <ng-template ngbNavContent>
          <div class="card-body border rounded">
            @for (fund of rightData?.fundList; let inx = $index; track fund) {
              <div class="fw-bold text-primary">สิทธิใช้เบิก</div>
              <div class="mt-3">
                @if (fund.mainInscl == 'UCS' || fund.mainInscl == 'WEL' || !fund.mainInscl) {
                  <app-uc-detail [fund]="fund"></app-uc-detail>
                } @else if (fund.mainInscl == 'SSS') {
                  <app-sss-detail [fund]="fund"></app-sss-detail>
                } @else if (fund.mainInscl == 'PVT') {
                  <app-pvt-detail [fund]="fund"></app-pvt-detail>
                } @else if (fund.mainInscl == 'WVO') {
                  <app-wvo-detail [fund]="fund"></app-wvo-detail>
                } @else if (fund.mainInscl == 'VET') {
                  <app-vet-detail [fund]="fund"></app-vet-detail>
                } @else if (fund.mainInscl == 'DIS') {
                  <app-dis-detail [fund]="fund"></app-dis-detail>
                } @else if (fund.mainInscl == 'NRD' || fund.mainInscl == 'NRU' || fund.mainInscl == 'FRG') {
                  <app-nrd-nru-frg-detail [fund]="fund"></app-nrd-nru-frg-detail>
                } @else if (fund.mainInscl == 'NRH') {
                  <app-nrh-detail [fund]="fund"></app-nrh-detail>
                } @else if (fund.mainInscl == 'ST1' || fund.mainInscl == 'ST2' || fund.mainInscl == 'STP') {
                  <app-st1-st2-stp-detail [fund]="fund"></app-st1-st2-stp-detail>
                  <!--            todo NRH-->
                } @else if (fund.mainInscl == 'OFC' || fund.mainInscl == 'LGO' || fund.mainInscl == 'SSI' || fund.mainInscl == 'BFC') {
                  <app-ofc-lgo-ssi-bfc-detail [fund]="fund"></app-ofc-lgo-ssi-bfc-detail>
                }
              </div>
            }
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <button ngbNavLink>ประวัติการเปลี่ยนแปลงสิทธิประกันสุขภาพ</button>
        <ng-template ngbNavContent>
          <app-right-history-detail [histories]="histories()"/>
        </ng-template>
      </li>
      <li [ngbNavItem]="3">
        <button ngbNavLink>กรมธรรม์สิทธิประโยชน์</button>
        <ng-template ngbNavContent>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
  </div>
</div>

