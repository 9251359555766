<div class="bg container-fluid">
  <div id="content" class="justify-content-center">
    <div class="container col-xl-3 col-md-6">
      <div style="height: 50px"></div>
      <div class="card shadow rounded-4">
        <div class="card-body bg-body-secondary rounded-4">
          <div class="container">
            <div class="text-center"><img src="./assets/images/nhso.png" alt="NHSO" style="width: 35%"
                                          class="shadow mt-3"></div>
            <div class="my-3 text-center">
              <h5 class="text-primary">ตรวจสอบสิทธิหลักประกันสุขภาพ</h5>
            </div>
            <div class="container">
              <form #form="ngForm" class="form-validate">
                <div>
                  <label for="pid"
                         class="text-start col-12 ui-require fs-6">เลขประจำตัวประชาชน</label>
                  <div class="col-12">
                    <input type="text" id="pid" name="pid" class="form-control" [(ngModel)]="params().pid"
                           pattern="^\d{13}$" required #pid="ngModel">
                    @if (pid.invalid && (pid.dirty || pid.touched)) {
                      <custom-error *ngIf="pid.hasError('required')" error="กรุณาระบุ"></custom-error>
                      <custom-error *ngIf="pid.hasError('pattern')"
                                    error="ระบุเป็นตัวเลข ความยาวเท่ากับ 13"></custom-error>
                    }
                  </div>
                </div>
                <div class="mt-3">
                  <label for="birthDate"
                         class="text-start col-12 ui-require fs-6">วันเดือนปีเกิด (ปี พ.ศ.)</label>
                  <div class="col-12">
                    <div class="input-group">
                      <input
                        id="birthDate"
                        class="form-control"
                        placeholder="วว/ดด/ปปปป"
                        name="dp"
                        ngbDatepicker
                        [minDate]="{year:1900,month:1,day:1}"
                        [maxDate]="maxDate"
                        (dateSelect)="dateSelect($event)"
                        [(ngModel)]="params().birthDate"
                        required
                        #d="ngbDatepicker"
                        #birthDateElement
                        #birthDate="ngModel">
                      <button class="btn btn-outline-secondary bi bi-calendar3" (click)="d.toggle()"
                              type="button"></button>
                    </div>
                    @if (birthDate.invalid && (birthDate.dirty || birthDate.touched)) {
                      <custom-error *ngIf="birthDate.hasError('required')" error="กรุณาระบุ"></custom-error>
                    }
                  </div>
                  <div class="col-12 text-secondary mt-1" style="font-size: 0.87rem">
                    ตัวอย่างเช่น 24/12/2524
                  </div>
                </div>
                <div class="row d-flex mt-3">
                  <div id="cf-turnstile"></div>
<!--                  <div-->
<!--                    class="cf-turnstile"-->
<!--                    [attr.data-sitekey]="environment.turnstileStiteKey"-->
<!--                    data-size="flexible"-->
<!--                    data-language="th-th"-->
<!--                    data-callback="turnstileCallback"-->
<!--                    data-error-callback="turnstileErrorCallback"-->
<!--                  ></div>-->
                </div>
                <div class="row mt-4 mb-3">
                  <button id="verifyBtn" class="btn btn-primary rounded-5 w-100 fs-6 py-3" type="submit"
                          (click)="verifyRight()"
                          [disabled]="!turnstileToken()">
                    ตรวจสอบสิทธิ
                  </button>
                </div>
              </form>
              <div class="col text-center text-muted mb-4">
                <div class="align-middle border-bottom border-secondary w-100">
                            <span class="position-relative  bg-body-secondary"
                                  style="font-size: 0.75rem; padding: 0 10px; top: 10px;">หรือ</span>
                </div>
              </div>
              <div class="row mb-3">
                <a class="btn text-light rounded-5" style="background:#030650" href="/api/secure/self-right-check">
                  <div class="row d-flex">
                    <div class="col-2"><img src="./assets/images/thaid.png" alt="ThaiD" height="40"></div>
                    <div class="col-10">เข้าสู่ระบบตรวจสอบสิทธิ และ
                      ลงทะเบียนด้วยตนเอง ด้วย ThaiD
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
