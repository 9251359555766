<div class="row mb-3">
  <label for="subInscl" class="col-xl-6 col-form-label text-end">สิทธิที่ใช้เบิก</label>
  @if (fund?.subInscl == 'ST') {
    <div class="col-auto">
      กองทุนประกันสุขภาพบุคคลที่มีปัญหาสถานะและสิทธิ (ตรวจสอบสิทธิจาก
      <a class="text-decoration-none" href="https://fdh.moph.go.th/inscl/"
         target="_blank">https://fdh.moph.go.th/inscl</a>
      )
    </div>
  } @else {
    <div class="col-xl-4">
      <input type="text" class="form-control" id="subInscl" formDisabled [ngModel]="fund?.subInsclDesc ">
    </div>
  }
</div>
<div class="row mb-3">
  <label for="ucProvince" class="col-xl-6 col-form-label text-end">จังหวัดที่ลงทะเบียนรักษา</label>
  <div class="col-xl-4">
    <input type="text" class="form-control" id="ucProvince"
           formDisabled [ngModel]="fund?.provinceName || '-' ">
  </div>
</div>
<div class="row mb-3">
  <label for="hsub" class="col-xl-6 col-form-label text-end">หน่วยบริการปฐมภูมิ</label>
  <div class="col-xl-4">
    <input type="text" class="form-control" id="hsub"
           formDisabled [ngModel]="fund?.hospSubName || '-' ">
  </div>
</div>
<div class="row mb-3">
  <label for="hmainOp" class="col-xl-6 col-form-label text-end">หน่วยบริการประจำ</label>
  <div class="col-xl-4">
    <input type="text" class="form-control" id="hmainOp"
           formDisabled [ngModel]="fund?.hospMainOpName || '-' ">
  </div>
</div>
