import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {RelationshipRightsMember} from '../../generated-model/model';
import {environment} from '../../environments/environment';
import {IgnoreNullHttpParams} from '../shere/commons/Ignore-null-http-params';

@Injectable({
  providedIn: 'root'
})
export class RelationshipRightsMemberService {
  http = inject(HttpClient)

  public getMember(memberPid: string) {
    return this.http.get<RelationshipRightsMember>(`${environment.serverUrl}/api/secure/get-member/${memberPid}`)
  }
}
