import {NgModule} from '@angular/core';
import {CommonModule, HashLocationStrategy, LocationStrategy} from '@angular/common';
import {RouterModule} from "@angular/router";
import {NgbModule, NgbPaginationModule, NgbScrollSpyModule, NgbScrollSpyService} from "@ng-bootstrap/ng-bootstrap";
import {NgSelectModule} from "@ng-select/ng-select";
import {GtErrorComponent} from './gt-error/gt-error.component';
import {CustomErrorComponent} from './custom-error.component';
import {FormDisabledDirective} from './form-disabled.directive';


@NgModule({
  exports: [
    NgbModule,
    NgbScrollSpyModule,
    NgbPaginationModule,
    NgSelectModule,
    GtErrorComponent,
    FormDisabledDirective
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    NgbScrollSpyModule,
    NgbPaginationModule,
    NgSelectModule,
    CustomErrorComponent,
    GtErrorComponent,
    FormDisabledDirective
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: 'localStorage',
      useValue: window.localStorage
    },
    NgbScrollSpyService
  ]
})
export class SharedCommonModule {
}
