<div class="row mb-3">
  <label for="mainInscl" class="col-xl-6 col-form-label text-end">สิทธิที่ใช้เบิก</label>
  <div class="col-xl-4">
    <input type="text" class="form-control" id="mainInscl" formDisabled
           ngModel="{{fund.subInsclDesc || '-'}}" >
    <!--todo {{fund.dbsDisplay}} -->
  </div>
  <!--สิทธิรัฐอื่น Flag = 0 สิทธิรัฐอื่น , Flag <>  0 จะไม่มีชื่อ-->
  <!--จากสิทธิกรมบัญชีกลาง,สิทธิกรุงเทพ Flag <> 0 , จากสิทธิกรมบัญชีกลาง,สิทธิกรุงเทพ Flag = 0 จะมีชื่อ-->
<!--
  @if (fund.name) {
    <label for="dbsDisplay" class="col-xl-6 col-form-label text-end">ชื่อ-สกุล (ตามหน่วยเบิก)</label>
    <div class="col-xl-4">
      <input type="text" class="form-control" id="dbsDisplay" formDisabled
             [ngModel]="fund.name || '-'">
    </div>
  }
-->

  @if (fund.mainInscl == 'SSI') {
    <label for="sssHospital" class="col-xl-6 col-form-label text-end">รพ. รักษา(ประกันสังคม)</label>
    <div class="col-xl-4">
      <input type="text" class="form-control" id="sssHospital" formDisabled
             [ngModel]="fund.hospSssName || '-'">
    </div>
  }

  @if (fund.mainInscl == 'LGO') {
    <label for="startDateTime" class="col-xl-6 col-form-label text-end">วันที่เริ่มปฏิบัติหน้าที่</label>
    <div class="col-xl-4">
      <input type="text" class="form-control" id="startDateTime"
             formDisabled [ngModel]="(fund?.startDate | thaidatetime: {month:'long'}) || '-'">
    </div>
  }
</div>
