import {Routes} from '@angular/router';
import {LayoutComponent} from './secure/layout/layout.component';
import {SelfRightCheckComponent} from './secure/right/self-right-check/self-right-check.component';
import {SelectFamilyComponent} from './secure/right/select-family/select-family.component';
import {FamilyRightCheckComponent} from './secure/right/family-right-check/family-right-check.component';
import {PublicRightComponent} from './public/public-right/public-right.component';
import {PublicRightDetailComponent} from './public/public-right-detail/public-right-detail.component';
import {HistoryComponent} from './secure/history/history.component';
import {ChangeHospitalComponent} from './secure/change-hospital/change-hospital.component';

export const routes: Routes = [
  {
    path: '',
    component:PublicRightComponent
  },
  {
    path:'public-right-detail',
    component:PublicRightDetailComponent
  },
  {
    path: 'secure',
    component: LayoutComponent,
    children: [
      {
        path:'',
        pathMatch: 'full',
        redirectTo:'self-right-check'
      },
      {
        path:'self-right-check',
        component:SelfRightCheckComponent
      },
      {
        path:'select-family-member',
        component:SelectFamilyComponent
      },
      {
        path:'select-family-member-detail',
        component:FamilyRightCheckComponent
      },
      {
        path:'change-hospital',
        component:ChangeHospitalComponent
      },
      {
        path:'history',
        component:HistoryComponent
      }
    ]
  }
];
