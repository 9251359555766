import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {RightsHistoryDto} from '../../generated-model/model';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RightHistoryResourceService {
  http = inject(HttpClient)

  public getHistory(pid: string){
  console.log('call_getHistory')
    return this.http.get<RightsHistoryDto[]>(`${environment.serverUrl}/api/secure/history/${pid}`)
  }
}
