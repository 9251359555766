import {Component, inject, signal} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AddFamilyModalService} from './modal/add-family-modal/add-family-modal.service';
import {RelationshipRightsMember} from '../../../../generated-model/model';
import {RightCheckResource} from '../../../service/right-check-resource.service';

@Component({
  selector: 'app-select-family',
  imports: [],
  templateUrl: './select-family.component.html',
  standalone: true,
  styleUrl: './select-family.component.scss'
})
export class SelectFamilyComponent {
  public router: Router = inject(Router);
  activatedRoute = inject(ActivatedRoute)
  private addFamilyModalService = inject(AddFamilyModalService)
  allFamilyMembers = signal([] as RelationshipRightsMember[])

  rightCheckResource = inject(RightCheckResource)

  ngOnInit() {
    this.rightCheckResource.getAllFamilyMember().subscribe({
      next: value => {
        this.allFamilyMembers.set(value)
      }
    })
  }

  select(member: RelationshipRightsMember) {
    this.router.navigate(['secure','select-family-member-detail'], {state:{...member}});
  }

  openAddFamilyModal() {
    this.addFamilyModalService.open().subscribe(
      {
        next: data => {
          console.log(data)
        },
        error: err => {
          console.log(err)
        }
      }
    )
  }
}
