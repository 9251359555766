import {Component} from '@angular/core';

@Component({
  selector: 'app-change-hospital-detail',
  imports: [],
  templateUrl: './change-hospital-detail.component.html',
  standalone: true,
  styleUrl: './change-hospital-detail.component.scss'
})
export class ChangeHospitalDetailComponent {
  addressType: string = 'MATCH';
}
