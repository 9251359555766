import {Component, inject, signal} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {RelationResourceService} from '../../../../../service/relation-resource.service';
import {Relation} from '../../../../../../generated-model/model';

@Component({
  selector: 'app-add-family-modal',
  imports: [],
  templateUrl: './add-family-modal.component.html',
  standalone: true,
  styleUrl: './add-family-modal.component.scss'
})
export class AddFamilyModalComponent {
  activeModal = inject(NgbActiveModal)
  relationResourceService = inject(RelationResourceService)
  relations = signal([] as Relation[])

  constructor() {
    this.relationResourceService.getAllRelation().subscribe({
      next: value => {
        this.relations.set(value)
      }, error: err => {
        if (err?.status == 400) {
          this.relations.set([])
          console.log(err)
        }
      }
    })
  }

  close() {
    this.activeModal.close('cross click')
  }
}
