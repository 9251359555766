/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 2.29.814 on 2024-12-14 10:22:02.

export interface Page<T> extends Slice<T> {
    totalPages?: number;
    totalElements?: number;
}

export interface Slice<T> extends Streamable<T> {
    first?: boolean;
    pageable?: Pageable;
    numberOfElements?: number;
    size?: number;
    content?: T[];
    number?: number;
    sort?: any;
    last?: boolean;
}

export interface BuildingType extends PanacheEntityBase {
    id?: number;
    name?: string;
    used?: YesNo;
}

export interface Catm {
    catm?: string;
    name?: string;
    changwatName?: string;
    amphurName?: string;
    tumbonName?: string;
    moobanName?: string;
    moo?: string;
}

export interface CurrentHomeAddress {
    adressNo?: string;
    trok?: string;
    soi?: string;
    road?: string;
    postCode?: string;
}

export interface District {
    code?: string;
    name?: string;
    province?: Province;
}

export interface Nation {
    id?: number;
    code?: string;
    name?: string;
    codeEn?: string;
    nameEn?: string;
}

export interface PersonalData extends PanacheEntityBase {
    personalId?: string;
    transId?: number;
    subPersonalId?: string;
    titleNameId?: string;
    titleName?: TitleName;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    motherId?: string;
    fatherId?: string;
    nationCode?: string;
    nation?: Nation;
    birthDate?: string;
    sex?: Sex;
    homeAddress?: PersonalHomeAddress;
    currentAddress?: CurrentHomeAddress;
    transDate?: any;
    statusDola?: string;
    deathDate?: any;
    disability?: boolean;
    bora?: string;
    createDate?: any;
    createBy?: string;
}

export interface PersonalHomeAddress {
    adressNo?: string;
    trok?: string;
    soi?: string;
    road?: string;
    postCode?: string;
    catm?: Catm;
    subDistrict?: SubDistrict;
    provinceId?: string;
}

export interface Province {
    id?: string;
    name?: string;
    zone?: Zone;
}

export interface Relation extends PanacheEntityBase {
    id?: number;
    name?: string;
    used?: boolean;
    code?: string;
}

export interface RelationshipRightsMember extends PanacheEntityBase {
    id?: RelationshipRightsMemberId;
    relation?: Relation;
    ownerData?: PersonalData;
    memberData?: PersonalData;
}

export interface RelationshipRightsMemberId {
    ownerId?: string;
    memberId?: string;
}

export interface Status {
    status?: string;
    statusDesc?: string;
    shortDesc?: string;
    owner?: StatusOwner;
}

export interface SubDistrict {
    code?: string;
    name?: string;
    district?: District;
    catm?: string;
}

export interface TitleName {
    id?: number;
    tname?: string;
    code?: string;
    sex?: string;
    ty?: string;
}

export interface Zone {
    id?: string;
    name?: string;
}

export interface FundDto {
    fundType?: string;
    mainInscl?: string;
    mainInsclDesc?: string;
    ownerFirstName?: string;
    ownerLastName?: string;
    ownerPersonalId?: string;
    ownerTitleName?: string;
    startDate?: string;
    expireDate?: string;
    subInscl?: string;
    subInsclDesc?: string;
    countSelect?: number;
    hospMainOp?: string;
    hospMainOpName?: string;
    hospSss?: string;
    hospSssName?: string;
    hospSub?: string;
    hospSubName?: string;
    hospMain?: string;
    hospMainName?: string;
    paidModel?: string;
    cardId?: string;
    province?: string;
    provinceName?: string;
}

export interface MastercupDto {
    id?: string;
    used?: string;
    remark?: string;
    mastercupId?: string;
    provinceId?: string;
    provinceName?: string;
    hospMainCode?: string;
    hospMainName?: string;
    hospSubCode?: string;
    hospSubName?: string;
    hospMainOpCode?: string;
    hospMainOpName?: string;
    paidModel?: string;
    dateInCup?: string;
    dateOutCup?: string;
    quota?: string;
    catm?: string;
    catmNear?: string;
    selectable?: boolean;
    hospSubDisplayName?: string;
    hospMainOpDisplayName?: string;
    hospMainDisplayName?: string;
}

export interface MastercupSearchParams {
    catm?: string;
    moo?: string;
    page?: number;
    size?: number;
    sort?: string[];
}

export interface PersonSearchParams {
}

export interface PersonalDataDto {
    birthDate?: string;
    fatherId?: string;
    motherId?: string;
    firstName?: string;
    lastName?: string;
}

export interface PublicRightCheckRequest {
    pid?: string;
    birthDate?: string;
    turnstileResponse?: string;
}

export interface RightsDto {
    checkDate?: string;
    firstName?: string;
    lastName?: string;
    birthDate?: string;
    sexCode?: string;
    sexDesc?: string;
    personalId?: string;
    statusDola?: string;
    statusDolaDesc?: string;
    fatherId?: string;
    motherId?: string;
    fundList?: FundDto[];
}

export interface RightsHistoryDto {
    transDate?: string;
    groupId?: number;
    fundType?: string;
    mainInscl?: string;
    mainInsclDesc?: string;
    startDate?: string;
    expireDate?: string;
    subInscl?: string;
    subInsclDesc?: string;
    hospMainOp?: string;
    hospMainOpName?: string;
    hospSub?: string;
    hospSubName?: string;
    hospMain?: string;
    hospMainName?: string;
    cardId?: string;
    province?: string;
    provinceName?: string;
    statusDesc?: string;
}

export interface UserData {
    fname?: string;
    lname?: string;
    pid?: string;
    fullName?: string;
}

export interface Pageable {
    paged?: boolean;
    unpaged?: boolean;
    pageSize?: number;
    pageNumber?: number;
    offset?: number;
    sort?: any;
}

export interface PanacheEntityBase {
}

export interface Streamable<T> extends Supplier<Stream<T>> {
    empty?: boolean;
}

export interface Supplier<T> {
}

export interface Stream<T> extends BaseStream<T, Stream<T>> {
}

export interface BaseStream<T, S> extends AutoCloseable {
    parallel?: boolean;
}

export interface AutoCloseable {
}

export type AddressType = "MATCH" | "MISMATCH";

export type Mode = "CREATE" | "READ" | "UPDATE" | "DELETE";

export type RegistrantType = "SELF" | "FAMILY";

export type Sex = "NULL" | "MALE" | "FEMALE";

export type StatusOwner = "NHSO" | "BORA";

export type YesNo = "Y" | "N";


// Added by 'EnumTypeAliasExtension' extension
export type AllEnumClass = "AddressType" | "Mode" | "RegistrantType" | "Sex" | "StatusOwner" | "YesNo"
// Enum description
export const AddressType : {[p in AddressType] : string } = {
   MATCH:'ตรงตามทะเบียนบ้าน',
   MISMATCH:'ไม่ตรงตามทะเบียนบ้าน'
}
//skip Mode because it not have getValue() method
export const RegistrantType : {[p in RegistrantType] : string } = {
   SELF:'ตัวเอง',
   FAMILY:'ครอบครัว'
}
export const Sex : {[p in Sex] : string } = {
   NULL:'null',
   MALE:'ชาย',
   FEMALE:'หญิง'
}
//skip StatusOwner because it not have getValue() method
export const YesNo : {[p in YesNo] : string } = {
   Y:'Yes',
   N:'No'
}


// Added by 'EnumTypeArrayExtension' extension
export const AddressTypeArray : AddressType[]  = ['MATCH','MISMATCH']
export const ModeArray : Mode[]  = ['CREATE','READ','UPDATE','DELETE']
export const RegistrantTypeArray : RegistrantType[]  = ['SELF','FAMILY']
export const SexArray : Sex[]  = ['NULL','MALE','FEMALE']
export const StatusOwnerArray : StatusOwner[]  = ['NHSO','BORA']
export const YesNoArray : YesNo[]  = ['Y','N']
