import {Component, Input} from '@angular/core';
import {FundDto} from '../../../../../generated-model/model';

@Component({
  selector: 'app-vet-detail',
  standalone: true,
  imports: [],
  templateUrl: './vet-detail.component.html',
  styleUrl: './vet-detail.component.scss'
})
export class VetDetailComponent {
  @Input() fund!: FundDto;

}
