import {Component, Input} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {SharedCommonModule} from '../../../../shere/commons/shared-common.module';
import {ThaiDateTimePipe} from '../../../../shere/commons/thai-datetime.pipe';
import {FundDto} from '../../../../../generated-model/model';

@Component({
  selector: 'app-ofc-lgo-ssi-bfc-detail',
  standalone: true,
  imports: [
    FormsModule,
    SharedCommonModule,
    ThaiDateTimePipe
  ],
  templateUrl: './ofc-lgo-ssi-bfc-detail.component.html',
  styleUrl: './ofc-lgo-ssi-bfc-detail.component.scss'
})
export class OfcLgoSsiBfcDetailComponent {
  @Input() fund!: FundDto;

}
