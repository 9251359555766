import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {IgnoreNullHttpParams} from '../shere/commons/Ignore-null-http-params';
import {PublicRightCheckRequest, RelationshipRightsMember, RightsDto} from '../../generated-model/model';

@Injectable({providedIn: "root"})
export class RightCheckResource {
  http = inject(HttpClient);

  public searchPublicRight(params: PublicRightCheckRequest) {
    return this.http.get<RightsDto>(`${environment.serverUrl}/api/public/check`, {
      params: IgnoreNullHttpParams.fromObject(params).toHttpParam()
    })
  }

  public searchRight(pid: string) {
    return this.http.get<RightsDto>(`${environment.serverUrl}/api/secure/check/${pid}`)
  }

  public getAllFamilyMember() {
    return this.http.get<RelationshipRightsMember[]>(`${environment.serverUrl}/api/secure/all-family-member`)
  }

  public setParent(fatherId: string, motherId: string) {
    return this.http.post(`${environment.serverUrl}/api/secure/set-parent`, {fatherId:fatherId,motherId:motherId})
  }
}
