import {Component, inject, Input} from '@angular/core';
import {FundDto, RegistrantType} from '../../../generated-model/model';
import {Router} from '@angular/router';

@Component({
  selector: 'app-register-panel',
  imports: [],
  templateUrl: './register-panel.component.html',
  standalone: true,
  styleUrl: './register-panel.component.scss'
})
export class RegisterPanelComponent {
  @Input() redirectUrl: string[] = [];
  @Input({required: true}) registrantType: RegistrantType
  @Input({required: true}) fundList: FundDto[]
  @Input({required: true}) pid: string

  router = inject(Router)

  register() {
    this.router.navigate(['secure', 'change-hospital'], {state: {registrantType: this.registrantType, pid: this.pid}})
  }

  showButton() {
    return this.fundList?.some(fund => fund.mainInscl == 'WEL' || fund.mainInscl == 'UCS' || fund.mainInscl == 'DIS')
  }
}
