import {Component, Input} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {SharedCommonModule} from '../../../../shere/commons/shared-common.module';
import {FundDto} from '../../../../../generated-model/model';


@Component({
  selector: 'app-nrd-nru-frg-detail',
  standalone: true,
  imports: [
    FormsModule,
    SharedCommonModule
  ],
  templateUrl: './nrd-nru-frg-detail.component.html',
  styleUrl: './nrd-nru-frg-detail.component.scss'
})
export class NrdNruFrgDetailComponent {
  @Input() fund!: FundDto;

}
