import {Component, inject, OnInit, signal} from '@angular/core';
import {RegistrantType, RelationshipRightsMember, RightsDto} from '../../../generated-model/model';
import {RightCheckComponent} from '../right/right-check/right-check.component';
import {ChangeHospitalDetailComponent} from './change-hospital-detail/change-hospital-detail.component';
import {
  FamilyRightCheckDetailComponent
} from '../right/family-right-check/family-right-check-detail/family-right-check-detail.component';
import {Router, RouterOutlet} from '@angular/router';
import {
  SelfRightCheckDetailComponent
} from '../right/self-right-check/self-right-check-detail/self-right-check-detail.component';
import {RightCheckResource} from '../../service/right-check-resource.service';
import {RelationshipRightsMemberService} from '../../service/relationship-rights-member.service';

@Component({
  selector: 'app-change-hospital',
  imports: [
    RightCheckComponent,
    ChangeHospitalDetailComponent,
    FamilyRightCheckDetailComponent,
    RouterOutlet,
    SelfRightCheckDetailComponent
  ],
  templateUrl: './change-hospital.component.html',
  standalone: true,
  styleUrl: './change-hospital.component.scss'
})
export class ChangeHospitalComponent implements OnInit {
  router = inject(Router)
  rightCheckResourceService = inject(RightCheckResource)
  relationRightMemberService = inject(RelationshipRightsMemberService)

  registrantType: RegistrantType
  pid: string
  data = signal(undefined as RightsDto)
  member = signal(undefined as RelationshipRightsMember)

  ngOnInit(): void {
    this.registrantType = this.router.getCurrentNavigation() ? this.router.getCurrentNavigation().extras.state['registrantType'] : this.router.lastSuccessfulNavigation.extras.state['registrantType'];
    this.pid = this.router.getCurrentNavigation() ? this.router.getCurrentNavigation().extras.state['pid'] : this.router.lastSuccessfulNavigation.extras.state['pid'];
    this.rightCheckResourceService.searchRight(this.pid).subscribe({
      next: value => {
        this.data.set(value)
      }, error: err => {
        if (err?.status == 400) {
          this.data.set(undefined);
          console.log(err)
        }
      }
    })
    if (this.registrantType == 'FAMILY') {
      this.relationRightMemberService.getMember(this.pid).subscribe({
        next: member => {
          this.member.set(member)
        }, error: err => {
          if (err?.status == 400) {
            this.member.set(undefined)
            console.log(err)
          }
        }
      })
    }
  }
}
