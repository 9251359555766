<div class="text-start my-3">
  <h5 class="text-secondary fw-bold">ตรวจสอบสิทธิบุคคลในครอบครัว</h5>
</div>
<div class="row d-flex mb-3">
  <div class="col-12 text-end">
    <button class="btn btn-primary fw-bold" (click)="selectFamilyMember()">
      เลือกคนในครอบครัว
    </button>
    <button class="btn btn-green fw-bold ms-2">
      <i class="bi bi-plus"></i> เพิ่มคนในครอบครัว
    </button>
  </div>
</div>
<div class="card mb-4 shadow-card">
  <div class="card-body rounded bg-warning-subtle">
    <div class="mb-3">
      <h5 class="fw-bold">บุคคลในครอบครัวที่เลือก</h5>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-xl-5 col-sm-12 d-flex">
          <label for="familyPid"
                 class="col-form-label fw-bold col-4">เลขประจำตัวประชาชน:</label>
          <div class="col-8">
            <input type="text" id="familyPid" class="fw-bold form-control-plaintext"
                   [ngModel]="member?.memberData.personalId">
          </div>
        </div>
        <div class="col-xl-5 col-sm-12 d-flex mt-sm-3 mt-xl-0">
          <label for="sex" class="col-form-label fw-bold col-4">เพศ:</label>
          <div class="col-8">
            <input type="text" id="sex" class="fw-bold form-control-plaintext"
                   [ngModel]="Sex[member?.memberData.sex]">
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-xl-5 col-sm-12 d-flex">
          <label for="familyName" class="col-form-label fw-bold col-4">ชื่อ-สกุล:</label>
          <div class="col-8">
            <input type="text" id="familyName" class="fw-bold form-control-plaintext"
                   value="{{member?.memberData.firstName}} {{member?.memberData.lastName}}">
          </div>
        </div>
        <div class="col-xl-5 col-sm-12 d-flex mt-sm-3 mt-xl-0">
          <label for="familyType" class="col-form-label fw-bold col-4">ความสัมพันธ์:</label>
          <div class="col-8">
            <input type="text" id="familyType" class="fw-bold form-control-plaintext"
                   [ngModel]="member?.relation.name">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
