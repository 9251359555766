import {Component, Input} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {SharedCommonModule} from '../../../../shere/commons/shared-common.module';
import {FundDto} from '../../../../../generated-model/model';

@Component({
  selector: 'app-pvt-detail',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    SharedCommonModule,
    FormsModule
  ],
  templateUrl: './pvt-detail.component.html',
  styleUrl: './pvt-detail.component.scss'
})
export class PvtDetailComponent {
  @Input() fund!: FundDto;

}
