import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UserData} from '../../generated-model/model';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserDataResourceService {
  http = inject(HttpClient);

  public getUserData() {
    return this.http.get<UserData>(`${environment.serverUrl}/api/userdata`);
  }
}
