<div class="card shadow-card mb-4">
  <div class="card-body mb-4">
    <div class="row mt-3">
      <label class="col-xl-2 fw-bold text-primary">ข้อมูลที่อยู่ปัจจุบัน</label>
      <div class="col-auto">
        <div class="form-check form-check-inline">
          <input type="radio" value="MATCH"
                 id="address_type_match" name="addressType" class="form-check-input" checked>
          <label for="address_type_match" class="form-check-label">ตรงตามทะเบียนบ้าน</label>
        </div>
        <div class="form-check form-check-inline">
          <input type="radio" value="MISMATCH"
                 id="address_type_mismatch"
                 name="addressType"
                 class="form-check-input">
          <label for="address_type_mismatch" class="form-check-label">ไม่ตรงตามทะเบียนบ้าน</label>
        </div>
      </div>
    </div>
    <div class="container mt-3" id="address">
      <div class="tab-content">
        @if (addressType == 'MATCH') {
          <div id="match-house-reg">
            <div class="row">
              <div class="col-xl-6 col-sm-12 d-xl-flex">
                <input type="hidden" value="{view.pid}" name="pid">
                <label for="buildingType"
                       class="col-form-label col-xl-3 col-sm-12 ui-require">ประเภทที่อยู่:</label>
                <div class="col-xl-8 col-sm-12">
                  <select name="" id="buildingType" class="form-select">
                    <option value="">กรุณาเลือก</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row mt-xl-3 mt-sm-0">
              <div class="col-xl-6 col-sm-12 d-xl-flex">
                <label for="addressNo"
                       class="col-form-label col-xl-3 col-sm-12">บ้านเลขที่/อาคาร:</label>
                <div class="col-xl-8 col-sm-12">
                  <input type="text" id="addressNo"
                         name="homeAddress[addressNo]"
                         class="form-control-plaintext" readonly>
                </div>
              </div>
              <div class="col-xl-6 col-sm-12 d-xl-flex mt-sm-3 mt-xl-0">
                <label for="moo"
                       class="col-form-label col-xl-3 col-sm-12">หมู่:</label>
                <div class="col-xl-8 col-sm-12">
                  <input type="text" id="moo"
                         name="homeAddress[catm][moo]"
                         class="form-control">
                </div>
              </div>
            </div>
            <div class="row mt-xl-3 mt-sm-0">
              <div class="col-xl-6 col-sm-12 d-xl-flex">
                <label for="alley"
                       class="col-form-label col-xl-3 col-sm-12">ตรอก:</label>
                <div class="col-xl-8 col-sm-12">
                  <input type="text" id="alley" name="" value="" class="form-control">
                </div>
              </div>
              <div class="col-xl-6 col-sm-12 d-xl-flex">
                <label for="soi"
                       class="col-form-label col-xl-3 col-sm-12">ซอย:</label>
                <div class="col-xl-8 col-sm-12">
                  <input type="text" id="soi"
                         name="homeAddress[soi]"
                         class="form-control">
                </div>
              </div>
            </div>
            <div class="row mt-xl-3 mt-sm-0">
              <div class="col-xl-6 col-sm-12 d-xl-flex">
                <label for="road"
                       class="col-form-label col-xl-3 col-sm-12">ถนน:</label>
                <div class="col-xl-8 col-sm-12">
                  <input type="text" id="road" name="" value="" class="form-control">
                </div>
              </div>
              <div class="col-xl-6 col-sm-12 d-xl-flex">
                <label for="subDistrict" class="col-form-label col-xl-3 col-sm-12 ui-require">ตำบล/แขวง:</label>
                <div class="col-xl-8 col-sm-12">
                  <input type="text" id="subDistrict"
                         name="homeAddress[catm][tumbonName]"
                         class="form-control-plaintext" readonly>
                </div>
              </div>
            </div>
            <div class="row mt-xl-3 mt-sm-0">
              <div class="col-xl-6 col-sm-12 d-xl-flex">
                <label for="district"
                       class="col-form-label col-xl-3 col-sm-12 ui-require">อำเภอ/เขต:</label>
                <div class="col-xl-8 col-sm-12">
                  <input type="text" id="district"
                         name="homeAddress[catm][amphurName]"
                         class="form-control-plaintext" readonly>
                </div>
              </div>
              <div class="col-xl-6 col-sm-12 d-xl-flex">
                <label for="province"
                       class="col-form-label col-xl-3 col-sm-12 ui-require">จังหวัด:</label>
                <div class="col-xl-8 col-sm-12">
                  <input type="text" id="province"
                         name="homeAddress[catm][changwatName]"
                         class="form-control-plaintext" readonly>
                </div>
              </div>
            </div>
            <div class="row mt-xl-3 mt-sm-0">
              <div class="col-xl-6 col-sm-12 d-xl-flex">
                <label for="phoneNo"
                       class="col-form-label col-xl-3 col-sm-12 ui-require">เบอร์โทรศัพท์มือถือ:</label>
                <div class="col-xl-8 col-sm-12">
                  <input type="text" id="phoneNo" name="" value="" placeholder="กรอกเฉพาะตัวเลข"
                         class="form-control">
                </div>
              </div>
              <div class="col-xl-6 col-sm-12 d-xl-flex">
                <label for="tel"
                       class="col-form-label col-xl-3 col-sm-12 ui-require">เบอร์โทรศัพท์:</label>
                <div class="col-xl-8 col-sm-12">
                  <input type="text" id="tel" value=""
                         placeholder="เบอร์โทรศัพท์หากมีมากกว่า 1 เบอร์ให้ใส่,คั่น"
                         class="form-control">
                </div>
              </div>
            </div>
            <div class="row mt-xl-3 mt-sm-0">
              <div class="col-xl-6 col-sm-12 d-xl-flex">
                <label for="email"
                       class="col-form-label col-xl-3 col-sm-12 ui-require">อีเมล:</label>
                <div class="col-xl-8 col-sm-12">
                  <input type="email" id="email" name="" value="" class="form-control">
                </div>
              </div>
              <div class="col-xl-6 col-sm-12 d-xl-flex">
                <label for="lineId"
                       class="col-form-label col-xl-3 col-sm-12 ui-require">Line ID:</label>
                <div class="col-xl-8 col-sm-12">
                  <input type="text" id="lineId" value="" class="form-control">
                </div>
              </div>
            </div>
          </div>
        } @else {
          <div id="not-match-house-reg">
            <div class="row">
              <div class="col-xl-6 col-sm-12 d-xl-flex">
                <input type="hidden" value="{view.pid}" name="pid">
                <label for="typeAddress2"
                       class="col-form-label col-xl-3 col-sm-12 ui-require">ประเภทที่อยู่:</label>
                <div class="col-xl-8 col-sm-12">
                  <select name="" id="typeAddress2" class="form-select">
                    <option value="">กรุณาเลือก</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row mt-xl-3 mt-sm-0">
              <div class="col-xl-6 col-sm-12 d-xl-flex">
                <label for="addressNo2"
                       class="col-form-label col-xl-3 col-sm-12">บ้านเลขที่/อาคาร:</label>
                <div class="col-xl-8 col-sm-12">
                  <input type="text" id="addressNo2" value="" class="form-control">
                </div>
              </div>
              <div class="col-xl-6 col-sm-12 d-xl-flex">
                <label for="moo2"
                       class="col-form-label col-xl-3 col-sm-12">หมู่:</label>
                <div class="col-xl-8 col-sm-12">
                  <input type="text" id="moo2" name="" class="form-control">
                </div>
              </div>
            </div>
            <div class="row mt-xl-3 mt-sm-0">
              <div class="col-xl-6 col-sm-12 d-xl-flex">
                <label for="alley"
                       class="col-form-label col-xl-3 col-sm-12">ตรอก:</label>
                <div class="col-xl-8 col-sm-12">
                  <input type="text" id="alley2" name="" value="" class="form-control">
                </div>
              </div>
              <div class="col-xl-6 col-sm-12 d-xl-flex">
                <label for="soi2"
                       class="col-form-label col-xl-3 col-sm-12">ซอย:</label>
                <div class="col-xl-8 col-sm-12">
                  <input type="text" id="soi2" name="" value="" class="form-control">
                </div>
              </div>
            </div>
            <div class="row mt-xl-3 mt-sm-0">
              <div class="col-xl-6 col-sm-12 d-xl-flex">
                <label for="road2"
                       class="col-form-label col-xl-3 col-sm-12">ถนน:</label>
                <div class="col-xl-8 col-sm-12">
                  <input type="text" id="road2" name="" value="" class="form-control">
                </div>
              </div>
              <div class="col-xl-6 col-sm-12 d-xl-flex">
                <label for="subDistrict2"
                       class="col-form-label col-xl-3 col-sm-12 ui-require">ตำบล/แขวง:</label>
                <div class="col-xl-8 col-sm-12">
                  <input type="text" id="subDistrict2" value="" class="form-control">
                </div>
              </div>
            </div>
            <div class="row mt-xl-3 mt-sm-0">
              <div class="col-xl-6 col-sm-12 d-xl-flex">
                <label for="district2"
                       class="col-form-label col-xl-3 col-sm-12 ui-require">อำเภอ/เขต:</label>
                <div class="col-xl-8 col-sm-12">
                  <input type="text" id="district2" name="" value="" class="form-control">
                </div>
              </div>
              <div class="col-xl-6 col-sm-12 d-xl-flex">
                <label for="province2"
                       class="col-form-label col-xl-3 col-sm-12 ui-require">จังหวัด:</label>
                <div class="col-xl-8 col-sm-12">
                  <input type="text" id="province2" name="homeAddress[catm][changwatName]" class="form-control">
                </div>
              </div>
            </div>
            <div class="row mt-xl-3 mt-sm-0">
              <div class="col-xl-6 col-sm-12 d-xl-flex">
                <label for="phoneNo2"
                       class="col-form-label col-xl-3 col-sm-12 ui-require">เบอร์โทรศัพท์มือถือ:</label>
                <div class="col-xl-8 col-sm-12">
                  <input type="number" id="phoneNo2" name="" value="" placeholder="กรอกเฉพาะตัวเลข"
                         class="form-control">
                </div>
              </div>
              <div class="col-xl-6 col-sm-12 d-xl-flex">
                <label for="tel2"
                       class="col-form-label col-xl-3 col-sm-12 ui-require">เบอร์โทรศัพท์:</label>
                <div class="col-xl-8 col-sm-12">
                  <input type="text" id="tel2" value=""
                         placeholder="เบอร์โทรศัพท์หากมีมากกว่า 1 เบอร์ให้ใส่,คั่น"
                         class="form-control">
                </div>
              </div>
            </div>
            <div class="row mt-xl-3 mt-sm-0">
              <div class="col-xl-6 col-sm-12 d-xl-flex">
                <label for="email2"
                       class="col-form-label col-xl-3 col-sm-12 ui-require">อีเมล:</label>
                <div class="col-xl-8 col-sm-12">
                  <input type="email" id="email2" name="" value="" class="form-control">
                </div>
              </div>
              <div class="col-xl-6 col-sm-12 d-xl-flex">
                <label for="lineId2"
                       class="col-form-label col-xl-3 col-sm-12 ui-require">Line ID:</label>
                <div class="col-xl-8 col-sm-12">
                  <input type="text" id="lineId2" value="" class="form-control">
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  </div>
</div>
<div class="card shadow-card mb-4">
  <div class="card-body">
    <div class="fw-bold text-primary">เครือข่ายหน่วยบริการของผู้ลงทะเบียน</div>
    <div class="row d-flex mb-3">
      <div class="col-12 text-xl-end text-sm-start">
        <button class="btn btn-outline-primary fw-bold mt-2">
          <i class="bi bi-hospital"></i> เลือกเครือข่ายหน่วยบริการ
        </button>
        <button class="btn btn-outline-warning fw-bold mt-2">
          <i class="bi bi-geo-alt-fill"></i> เลือกเครือข่ายหน่วยบริการผ่านแผนที่
        </button>
      </div>
    </div>
    <div class="mt-3 d-xl-flex justify-content-center mb-3">
      <div class="col-xl-5 col-sm-12 me-xl-5 mt-3">
        <div class="text-decoration-underline fw-bold mb-3">
          เครือข่ายหน่วยบริการเดิม (ปัจจุบัน)
        </div>
        <div class="card bg-secondary">
          <div class="card-body">
            <div class="mt-3 d-xl-flex">
              <label for="hcodeOld" class="col-form-label col-xl-4 col-sm-12 text-white">รหัสหน่วยบริการเดิม:</label>
              <div class="col-xl-8 col-sm-12">
                <input type="text" id="hcodeOld" name="" class="form-control-plaintext text-white">
              </div>
            </div>
            <div class="mt-3 d-xl-flex">
              <label for="provinceOld"
                     class="col-form-label col-xl-4 col-sm-12 text-white">จังหวัดที่ลงทะเบียนเดิม:</label>
              <div class="col-xl-8 col-sm-12">
                <input type="text" id="provinceOld" name=""
                       class="form-control-plaintext text-white">
              </div>
            </div>
            <div class="mt-3 d-xl-flex">
              <label for="hmainOpOld"
                     class="col-form-label col-xl-4 col-sm-12 text-white">หน่วยบริการประจำเดิม:</label>
              <div class="col-xl-8 col-sm-12">
                <input type="text" id="hmainOpOld" name=""
                       class="form-control-plaintext text-white">
              </div>
            </div>
            <div class="mt-3 d-xl-flex">
              <label for="hsubOld"
                     class="col-form-label col-xl-4 col-sm-12 text-white">หน่วยบริการปฐมภูมิเดิม:</label>
              <div class="col-xl-8 col-sm-12">
                <input type="text" id="hsubOld" name=""
                       class="form-control-plaintext text-white">
              </div>
            </div>
            <div class="mt-3 d-xl-flex">
              <label for="hmainOld"
                     class="col-form-label col-xl-4 col-sm-12 text-white">หน่วยบริการที่รับการส่งต่อเดิม:</label>
              <div class="col-xl-8 col-sm-12">
                <input type="text" id="hmainOld" name=""
                       class="form-control-plaintext text-white">
              </div>
            </div>
            <div class="mt-3 d-xl-flex">
              <label for="modelOld" class="col-form-label col-xl-4 col-sm-12 text-white">รูปแบบการจัดสรรเงิน:</label>
              <div class="col-xl-8 col-sm-12">
                <input type="text" id="modelOld" name=""
                       class="form-control-plaintext text-white">
              </div>
            </div>
            <div class="my-3 d-xl-flex">
              <label for="statusOld" class="col-form-label col-xl-4 col-sm-12 text-white">สถานะเครือข่าย:</label>
              <div class="col-xl-8 col-sm-12">
                <input type="text" id="statusOld" name="" value=""
                       class="form-control-plaintext text-white">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-5 col-sm-12 mt-3" id="result">
        <div class="text-decoration-underline fw-bold mb-3">
          เครือข่ายหน่วยบริการใหม่ (ที่ต้องการเปลี่ยน)
        </div>
        <div class="card">
          <div class="card-body">
            <div class="mt-3 d-xl-flex">
              <label for="mastercupId"
                     class="col-form-label col-xl-4 col-sm-12">รหัสเครือข่าย:</label>
              <div class="col-xl-8 col-sm-12">
                <input type="text" id="mastercupId" name="" class="form-control-plaintext">
              </div>
            </div>
            <div class="mt-3 d-xl-flex">
              <label for="provinceNew"
                     class="col-form-label col-xl-4 col-sm-12">จังหวัดที่ลงทะเบียน:</label>
              <div class="col-8">
                <input type="text" id="provinceNew" name="mastercupNew[provinceName]" class="form-control-plaintext">
              </div>
            </div>
            <div class="mt-3 d-xl-flex">
              <label for="hmainOp"
                     class="col-form-label col-xl-4 col-sm-12">หน่วยบริการประจำ:</label>
              <div class="col-xl-8 col-sm-12">
                <input type="text" id="hmainOp" name="mastercupNew[hospMainOpDisplayName]"
                       class="form-control-plaintext">
              </div>
            </div>
            <div class="mt-3 d-xl-flex">
              <label for="hsub"
                     class="col-form-label col-xl-4 col-sm-12">หน่วยบริการปฐมภูมิ:</label>
              <div class="col-xl-8 col-sm-12">
                <input type="text" id="hsub" name="mastercupNew[hospSubDisplayName]" class="form-control-plaintext">
              </div>
            </div>
            <div class="mt-3 d-xl-flex">
              <label for="hmain" class="col-form-label col-xl-4 col-sm-12">หน่วยบริการที่รับการส่งต่อ:</label>
              <div class="col-xl-8 col-sm-12">
                <input type="text" id="hmain" name="mastercupNew[hospMainDisplayName]" class="form-control-plaintext">
              </div>
            </div>
            <div class="mt-3 d-xl-flex">
              <label for="model"
                     class="col-form-label col-xl-4 col-sm-12">รูปแบบการจัดสรรเงิน:</label>
              <div class="col-xl-8 col-sm-12">
                <input type="text" id="model" name="mastercupNew[paidModel]" class="form-control-plaintext">
              </div>
            </div>
            <div class="my-3 d-xl-flex">
              <label for="status" class="col-form-label col-xl-4">สถานะเครือข่าย:</label>
              <div class="col-xl-8 col-sm-12">
                <input type="text" id="status" name="mastercupNew[quota]"
                       class="form-control-plaintext">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="card shadow-card mb-4">
  <div class="card-body">
    <div class="justify-content-xl-center justify-content-sm-start d-flex fw-bold mt-3">
      <input type="checkbox" class="form-check-input me-2" id="accept">
      <label for="accept" class="form-check-label">รับข้อตกลง และยืนยันการลงทะเบียน xxxxxxxxx</label>
    </div>
  </div>
</div>
<div class="text-center mb-4">
  <div class="col-12">
    <button class="btn btn-success">
      <i class="bi bi-floppy-fill me-1"></i>
      ยืนยันการลงทะเบียน
    </button>
  </div>
</div>
