import {Component, inject, signal} from '@angular/core';
import {RightCheckComponent} from '../right-check/right-check.component';
import {Router} from '@angular/router';
import {RightsDto, RightsHistoryDto, UserData} from '../../../../generated-model/model';
import {RightCheckResource} from '../../../service/right-check-resource.service';
import {UserDataService} from '../../../service/user-data.service';
import {RightHistoryResourceService} from '../../../service/right-history-resource.service';
import {RegisterPanelComponent} from '../../register-panel/register-panel.component';
import {SelfRightCheckDetailComponent} from './self-right-check-detail/self-right-check-detail.component';

@Component({
  selector: 'app-self-right-check',
  imports: [RightCheckComponent, RegisterPanelComponent, SelfRightCheckDetailComponent],
  templateUrl: './self-right-check.component.html',
  standalone: true,
  styleUrl: './self-right-check.component.scss'
})
export class SelfRightCheckComponent {
  router: Router = inject(Router)
  userDataService = inject(UserDataService)
  rightCheckResource = inject(RightCheckResource)
  rightHistoryResource = inject(RightHistoryResourceService)

  userData = signal({} as UserData)
  data = signal({} as RightsDto)
  histories = signal([] as RightsHistoryDto[])

  ngOnInit() {
    this.userDataService.getCurrent().subscribe({
      next: value => {
        this.userData.set(value)
        this.rightCheckResource.searchRight(this.userData().pid).subscribe({
            next: data => {
              this.data.set(data)
              if (this.data().fatherId || this.data().motherId) {
                this.rightCheckResource.setParent(this.data().fatherId, this.data().motherId).subscribe({
                  next: value => {
                    console.log('setParent')
                  }
                })
              }

            }, error: err => {
              if (err?.status == 400) {
                this.data.set(undefined);
                console.log(err)
              }
            }
          }
        )
      }
    })
  }

  register() {
    this.router.navigate(['secure', 'change-hospital-self'])
  }

  showButton() {
    return this.data()?.fundList?.some(fund => fund.mainInscl == 'WEL' || fund.mainInscl == 'UCS' || fund.mainInscl == 'DIS')
  }

  getHistory() {
    this.rightHistoryResource.getHistory(this.userData().pid).subscribe({
      next: value => {
        console.log(value)
        this.histories.set(value)
      }, error: err => {
        console.log(err)
      }
    })
  }
}
