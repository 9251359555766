import {Component, Input} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {ThaiDateTimePipe} from '../../../../shere/commons/thai-datetime.pipe';
import {SharedCommonModule} from '../../../../shere/commons/shared-common.module';
import {FundDto} from '../../../../../generated-model/model';

@Component({
  selector: 'app-sss-detail',
  standalone: true,
  imports: [
    FormsModule,
    ThaiDateTimePipe,
    SharedCommonModule
  ],
  templateUrl: './sss-detail.component.html',
  styleUrl: './sss-detail.component.scss'
})
export class SssDetailComponent {
  @Input() fund!: FundDto;

}
