import {inject, Injectable, OnInit, signal} from '@angular/core';
import {UserDataResourceService} from './user-data-resource.service';
import {Observable, of, tap} from 'rxjs';
import {UserData} from '../../generated-model/model';

@Injectable({
  providedIn: 'root'
})
export class UserDataService implements OnInit {
  private userDataResource = inject(UserDataResourceService);
  private userData = signal<UserData>(undefined);

  ngOnInit() {

  }

  private getUserData() {
    return this.userDataResource.getUserData();
  }

  getCurrent(): Observable<UserData> {
    if (!this.userData()) {
      return this.getUserData()
        .pipe(
          tap(e => this.userData.set(e))
        );
    }
    return of(this.userData());
  }
}
