<div class="container-fluid">
  <div class="row">
    <ng-template #sidebarOffcanvas let-offcanvas>
      <div class="offcanvas-header">
        <h6 class="offcanvas-titletext-muted" id="offcanvas-basic-title" ngbAutofocus>
          ระบบตรวจสอบสิทธิ และลงทะเบียนภาคประชาชน</h6>
        <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
      </div>
      <div class="offcanvas-body">
        <div id="sidebar-offcanvas">
          <div tabindex="-1" aria-labelledby="sidebar-menu-label">
            <div class="flex-column">
              <ng-container [ngTemplateOutlet]="sidebar"></ng-container>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <div #collapse="ngbCollapse" [(ngbCollapse)]="isSidebarCollapsed" [horizontal]="true" class="col-auto p-0">
      <div id="sidebar">
        <div id="sidebar-menu" tabindex="-1" aria-labelledby="sidebar-menu-label">
          <div class="flex-column">
            <ng-container [ngTemplateOutlet]="sidebar"></ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="col overflow-y-auto p-0">
      <header id="header" class="sticky-top w-100 position-relative">
        <nav class="navbar navbar-expand-lg">
          <div class="container-fluid">
            @if (toggleOffCanvas) {
              <button class="navbar-toggler d-block border-0 rounded px-1" type="button"
                      (click)="openSidebarOffcanvas(sidebarOffcanvas)">
                <i class="bi bi-layout-sidebar text-white fs-3"></i>
              </button>
            } @else {
              <button class="navbar-toggler d-block border-0 rounded px-1" type="button"
                      [attr.aria-expanded]="!isSidebarCollapsed"
                      (click)="collapse.toggle()">
                <i class="bi bi-layout-sidebar text-white fs-3"></i>
              </button>
            }
            <div class="collapse navbar-collapse ms-3" id="navbarSupportedContent">
              <ul id="select-font-size" class="navbar-nav me-auto mb-lg-0">
                <li class="nav-item me-1" (click)="setFontSizeTo('14px')">
                  <input type="radio" class="btn-check" name="options" id="fs14px" [value]="'14px'"
                         [(ngModel)]="selectedFontSize" autocomplete="off">
                  <label class="btn btn-outline-light py-0" for="fs14px">ก</label>
                </li>
                <li class="nav-item me-1" (click)="setFontSizeTo('16px')">
                  <input type="radio" class="btn-check" name="options" id="fs16px" [value]="'16px'"
                         [(ngModel)]="selectedFontSize" autocomplete="off">
                  <label class="btn btn-outline-light py-0" for="fs16px">ก+</label>
                </li>
                <li class="nav-item me-1" (click)="setFontSizeTo('18px')">
                  <input type="radio" class="btn-check" name="options" id="fs18px" [value]="'18px'"
                         [(ngModel)]="selectedFontSize" autocomplete="off">
                  <label class="btn btn-outline-light py-0" for="fs18px">ก++</label>
                </li>
              </ul>
              <div class="dropdown flex-row text-end me-1">
                <a role="button" class="d-block text-decoration-none dropdown-toggle"
                   data-bs-toggle="dropdown" aria-expanded="false">
                  <span class="fw-bold">{{ userFullName() }}</span>
                  <i class="bi-person-circle ms-2 fs-3 "></i>
                </a>
                <ul class="dropdown-menu dropdown-menu-start text-small" style="right: 0;">
                  <li class="cursor-pointer"><a href="/api/logout" class="dropdown-item"><i
                    class="bi bi-box-arrow-in-right me-2"></i>ออกจากระบบ</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <div id="content">
        <div class="ps-3 pt-3 pe-3 pb-5 mb-3">
          <app-loading></app-loading>
          <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
          <router-outlet/>
        </div>
      </div>
    </div>
    <ng-template #sidebar>
      @if (!toggleOffCanvas) {
        <ul class="nav flex-column sticky-top" style="background-color:white; height: 52px;">
          <li class="nav-item d-flex justify-content-center p-2">
            <a class="navbar-brand d-none d-md-flex">
              <img src="/assets/images/nhso-minimize.webp" alt="Logo" class="">
              <div class="fw-bold text-muted">ระบบตรวจสอบสิทธิ และลงทะเบียนภาคประชาชน</div>
            </a>
          </li>
        </ul>
      }
      <div id="sidebar-content">
        <ul class="nav flex-column">
          <li class="nav-item" data-bs-toggle="collapse" data-bs-target="#verify-right-collapse"
              aria-expanded="true">
            <a class="nav-link nav-link-collapse">
              <i class="bi bi-shield-check"></i>
              <span>ตรวจสอบข้อมูล</span>
              <i class="rtl:rotate-180 right-icon"
                 x-bind:style="selected.includes('menu_style') ? 'transform:  rotate(90deg)' : ''" style="">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
                </svg>
              </i>
            </a>
          </li>
          <div class="collapse show" id="verify-right-collapse" style="">
            <li class="nav-item">
              <a routerLink="self-right-check" routerLinkActive="active"
                 class="nav-link btn  d-flex align-items-center align-middle  border-0 gap-2">
                ตรวจสอบสิทธิตนเอง
              </a>
            </li>
            <li class="nav-item">
              <a routerLink="select-family-member" routerLinkActive="active"
                 class="nav-link btn  d-flex align-items-center align-middle  border-0 gap-2">
                ตรวจสอบสิทธิคนในครอบครัว
              </a>
            </li>
            <li class="nav-item">
              <a routerLink="history" routerLinkActive="active"
                 class="nav-link btn  d-flex align-items-center align-middle  border-0 gap-2">
                ตรวจสอบประวัติการลงทะเบียน
              </a>
            </li>
          </div>
        </ul>
      </div>
    </ng-template>
  </div>
</div>
