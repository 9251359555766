<div class="container-fluid" xmlns:x-bind="http://www.w3.org/1999/xhtml">
  <div class="row">
    <div class="col-auto d-xl-block d-none p-0">
      <div id="sidebar">
        <div id="sidebar-menu" class="offcanvas-md offcanvas-end" tabindex="-1" aria-labelledby="sidebar-menu-label">
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="sidebar-menu-label">
              ระบบลงทะเบียนสิทธิหลักประกันสุขภาพแห่งชาติ</h5>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#sidebar-menu"
                    aria-label="Close"></button>
          </div>
          <div class="offcanvas-body d-md-flex flex-column">
            <ul class="nav flex-column sticky-top bg-white">
              <li class="nav-item d-flex justify-content-center p-2">
                <a class="navbar-brand d-none d-md-flex">
                  <img src="/assets/images/nhso-minimize.webp" alt="Logo" class="">
                  <div class="fw-semibold text-muted">ระบบตรวจสอบสิทธิ และลงทะเบียนภาคประชาชน</div>
                </a>
              </li>
            </ul>
            <div id="sidebar-content">
              <ul class="nav flex-column">
                <li class="nav-item" data-bs-toggle="collapse" data-bs-target="#verify-right-collapse">
                  <a class="nav-link nav-link-collapse">
                    <i class="bi bi-shield-check"></i>
                    <span>ตรวจสอบข้อมูล</span>
                    <i class="rtl:rotate-180 right-icon"
                       x-bind:style="selected.includes('menu_style') ? 'transform:  rotate(90deg)' : ''" style="">
                      <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24"
                           stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
                      </svg>
                    </i>
                  </a>
                </li>
                <div class="collapse" id="verify-right-collapse" style="">
                  <li class="nav-item">
                    <a routerLink="self-right-check" routerLinkActive="active"
                       class="nav-link btn  d-flex align-items-center align-middle  border-0 gap-2">
                      ตรวจสอบสิทธิตนเอง
                    </a>
                  </li>
                  <li class="nav-item">
                    <a routerLink="select-family-member" routerLinkActive="active"
                       class="nav-link btn  d-flex align-items-center align-middle  border-0 gap-2">
                      ตรวจสอบสิทธิคนในครอบครัว
                    </a>
                  </li>
                </div>
              </ul>
              <ul class="nav flex-column">
                <li class="nav-item" data-bs-toggle="collapse" data-bs-target="#registration-collapse">
                  <a class="nav-link nav-link-collapse">
                    <i class="bi bi-person"></i>
                    <span>ลงทะเบียน</span>
                    <i class="rtl:rotate-180 right-icon"
                       x-bind:style="selected.includes('menu_style') ? 'transform:  rotate(90deg)' : ''" style="">
                      <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24"
                           stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
                      </svg>
                    </i>
                  </a>
                </li>
                <div class="collapse" id="registration-collapse" style="">
                  <li class="nav-item">
                    <a routerLink="change-hospital" routerLinkActive="active"
                       class="nav-link btn  d-flex align-items-center align-middle  border-0 gap-2">
                      เปลี่ยนหน่วยบริการ
                    </a>
                  </li>
                  <li class="nav-item">
                    <a routerLink="history" routerLinkActive="active"
                       class="nav-link btn  d-flex align-items-center align-middle  border-0 gap-2">
                      ตรวจสอบประวัติการลงทะเบียน
                    </a>
                  </li>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    }-->
    <div class="col overflow-y-auto p-0">
      <header id="header">
        <nav class="navbar navbar-expand-lg">
          <div class="container-fluid">
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation">
              <i class="bi bi-layout-sidebar fs-3"></i>
            </button>
            <button class="navbar-toggler d-lg-block border-0 px-1" type="button" (click)="sidebarToggle()">
              <i class="bi bi-layout-sidebar text-white fs-3"></i>
            </button>
            <div class="collapse navbar-collapse ms-3" id="navbarSupportedContent">
              <ul id="select-font-size" class="navbar-nav me-auto mb-lg-0">
                <li class="nav-item me-1" (click)="setFontSizeTo('14px')">
                  <input type="radio" class="btn-check" name="options" id="fs14px" [value]="'14px'"
                         [(ngModel)]="selectedFontSize" autocomplete="off">
                  <label class="btn btn-outline-light py-0" for="fs14px">ก</label>
                </li>
                <li class="nav-item me-1" (click)="setFontSizeTo('16px')">
                  <input type="radio" class="btn-check" name="options" id="fs16px" [value]="'16px'"
                         [(ngModel)]="selectedFontSize" autocomplete="off">
                  <label class="btn btn-outline-light py-0" for="fs16px">ก+</label>
                </li>
                <li class="nav-item me-1" (click)="setFontSizeTo('18px')">
                  <input type="radio" class="btn-check" name="options" id="fs18px" [value]="'18px'"
                         [(ngModel)]="selectedFontSize" autocomplete="off">
                  <label class="btn btn-outline-light py-0" for="fs18px">ก++</label>
                </li>
              </ul>
              <div class="dropdown flex-row text-end me-1">
                <a role="button" class="d-block text-decoration-none dropdown-toggle"
                   data-bs-toggle="dropdown" aria-expanded="false">
                  {{ userFullName() }}
                </a>
                <ul class="dropdown-menu dropdown-menu-start text-small" style="right: 0;">
                  <li class="cursor-pointer"><a (click)="logout()" class="dropdown-item"><i
                    class="bi bi-box-arrow-in-right me-2"></i>ออกจากระบบ</a>
                  </li>
                </ul>
              </div>

              <ul class="navbar-nav flex-row d-md-none">
                <li class="nav-item text-nowrap">
                  <button class="nav-link px-3"
                          type="button"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#sidebar-menu" aria-controls="sidebar-menu" aria-expanded="false"
                          aria-label="Toggle navigation">
                    <span class="bi bi-list"></span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <div id="content">
        <div class="ps-3 pt-3 pe-3 pb-5 mb-5">
          <!--    <app-loading></app-loading>
              <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
              <app-header></app-header>-->
          <router-outlet/>
        </div>
      </div>
    </div>
  </div>
</div>
