import {Component, Input} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {SharedCommonModule} from '../../../../shere/commons/shared-common.module';
import {FundDto} from '../../../../../generated-model/model';

@Component({
  selector: 'app-st1-st2-stp-detail',
  standalone: true,
  imports: [
    FormsModule,
    SharedCommonModule
  ],
  templateUrl: './st1-st2-stp-detail.component.html',
  styleUrl: './st1-st2-stp-detail.component.scss'
})
export class St1St2StpDetailComponent {
  @Input() fund!: FundDto;

}
