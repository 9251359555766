import { Component } from '@angular/core';

@Component({
  selector: 'app-self-right-check-detail',
  imports: [],
  templateUrl: './self-right-check-detail.component.html',
  standalone: true,
  styleUrl: './self-right-check-detail.component.scss'
})
export class SelfRightCheckDetailComponent {

}
