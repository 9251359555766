import {Component, Input} from '@angular/core';
import {FormsModule} from "@angular/forms";

import {ThaiDateTimePipe} from '../../../../shere/commons/thai-datetime.pipe';
import {SharedCommonModule} from '../../../../shere/commons/shared-common.module';
import {FundDto} from '../../../../../generated-model/model';

@Component({
  selector: 'app-uc-detail',
  standalone: true,
  imports: [
    FormsModule,
    SharedCommonModule,
    ThaiDateTimePipe
  ],
  templateUrl: './uc-detail.component.html',
  styleUrl: './uc-detail.component.scss'
})
export class UcDetailComponent {
  @Input() fund!: FundDto;

}
