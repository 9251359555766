import {Component, Input} from '@angular/core';
import {RightsHistoryDto} from '../../../../generated-model/model';
import {ThaiDateTimePipe} from '../../../shere/commons/thai-datetime.pipe';

@Component({
  selector: 'app-right-history-detail',
  imports: [
    ThaiDateTimePipe
  ],
  templateUrl: './right-history-detail.component.html',
  standalone: true,
  styleUrl: './right-history-detail.component.scss'
})
export class RightHistoryDetailComponent {
  @Input() histories: RightsHistoryDto[]

  constructor() {
    console.log(this.histories)
  }
}
