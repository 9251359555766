import {Component, inject, Input, OnChanges, OnInit, output, signal, SimpleChanges} from '@angular/core';
import {NgClass} from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FundDto, RightsDto, RightsHistoryDto} from '../../../../generated-model/model';
import {FormsModule} from '@angular/forms';
import {BirthdateWithoutDate} from '../../../shere/birthdate-without-date';
import {DisDetailComponent} from '../right-detail/dis/dis-detail.component';
import {NrdNruFrgDetailComponent} from '../right-detail/nrd-nru-frg/nrd-nru-frg-detail.component';
import {NrhDetailComponent} from '../right-detail/nrh/nrh-detail.component';
import {OfcLgoSsiBfcDetailComponent} from '../right-detail/ofc-lgo-ssi-bfc/ofc-lgo-ssi-bfc-detail.component';
import {PvtDetailComponent} from '../right-detail/pvt/pvt-detail.component';
import {SssDetailComponent} from '../right-detail/sss/sss-detail.component';
import {St1St2StpDetailComponent} from '../right-detail/st1-st2-stp/st1-st2-stp-detail.component';
import {UcDetailComponent} from '../right-detail/uc/uc-detail.component';
import {VetDetailComponent} from '../right-detail/vet/vet-detail.component';
import {WvoDetailComponent} from '../right-detail/wvo/wvo-detail.component';
import {RightHistoryDetailComponent} from '../right-history-detail/right-history-detail.component';
import {RightHistoryResourceService} from '../../../service/right-history-resource.service';
import {ThaiDateTimePipe} from '../../../shere/commons/thai-datetime.pipe';

@Component({
  selector: 'app-right-check',
  imports: [
    NgClass,
    NgbModule,
    FormsModule,
    BirthdateWithoutDate,
    DisDetailComponent,
    NrdNruFrgDetailComponent,
    NrhDetailComponent,
    OfcLgoSsiBfcDetailComponent,
    PvtDetailComponent,
    SssDetailComponent,
    St1St2StpDetailComponent,
    UcDetailComponent,
    VetDetailComponent,
    WvoDetailComponent,
    RightHistoryDetailComponent,
    ThaiDateTimePipe
  ],
  templateUrl: './right-check.component.html',
  standalone: true,
  styleUrl: './right-check.component.scss'
})
export class RightCheckComponent implements OnChanges {
  rightHistoryResource = inject(RightHistoryResourceService)
  @Input() title: string
  @Input() active = 1
  @Input({required: true}) rightData: RightsDto

  onTabChange = output<boolean>()
  histories = signal([] as RightsHistoryDto[])

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['rightData']) {
      console.log('change ' + changes['rightData'].currentValue?.personalId)
      this.rightHistoryResource.getHistory(changes['rightData'].currentValue?.personalId)
        .subscribe(value => {
          this.histories.set(value);
        });

      if (changes['rightData']?.currentValue?.checkDateBySpecificTime) {
        this.active = 99;
      } else {
        this.active = 1;
      }
    } else if (!changes['rightData']?.currentValue) {
      this.histories.set([]);
    }
  }
}
